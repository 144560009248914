<template>
    <div>
        <div class="box">
            <HistoriqueOrdonnance :horse_id="this.horse_id" />
        </div>
    </div>
</template>


<script type="text/javascript">
    import HistoriqueOrdonnance from '@/components/HistoriqueOrdonnance'

	export default {
		name: "ordonnancesHorse",
        props: [
            'horse_id',
        ],
		components: {
            HistoriqueOrdonnance
		}
	}
</script>
